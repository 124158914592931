<template>
    <div class="section__content">
        <div class="content__form">
            <div class="form__item">
                <label class="item__name" for="inptName">Название:</label>
                <input class="item__input" v-model="this.form.name" type="text" placeholder="Введите название" id="inptName">
            </div>
            <div class="form__item">
                <label class="item__name" for="inptUrl">Ссылка:</label>
                <input class="item__input" v-model="this.form.url" type="text" placeholder="Введите ссылку" id="inptUrl">
            </div>
            <div class="form__item">
                <label class="item__name" for="inptUrls">Ссылка на соц сеть:</label>
                <div  v-for="(item, index) in this.form.social" class="d">
                    <input v-model="this.form.social[index]" class="item__input" type="text" placeholder="Введите ссылку" id="inptUrls">
                    <svg v-on:click="this.form.social.splice(index, 1)" v-if="index > 0" class="minus__btn" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.66669 16H25.3334" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="form__item" style="align-items: center">
                <svg v-on:click="this.form.social.push('')" class="plus__btn" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 17.3334H17.3334V24C17.3334 24.7334 16.7334 25.3334 16 25.3334C15.2667 25.3334 14.6667 24.7334 14.6667 24V17.3334H8.00002C7.26669 17.3334 6.66669 16.7334 6.66669 16C6.66669 15.2667 7.26669 14.6667 8.00002 14.6667H14.6667V8.00002C14.6667 7.26669 15.2667 6.66669 16 6.66669C16.7334 6.66669 17.3334 7.26669 17.3334 8.00002V14.6667H24C24.7334 14.6667 25.3334 15.2667 25.3334 16C25.3334 16.7334 24.7334 17.3334 24 17.3334Z" fill="black"/>
                </svg>
            </div>
            <div class="form__item">
                <label class="item__name" for="inptAc">Аккорды и текст песни:</label>
                <textarea name="a" class="item__input ar" v-model="this.form.text" id="inptAc" cols="30" rows="10" placeholder="Введите текст"></textarea>
            </div>
            <div class="form__item"  style="align-items: center;" >
                <div ref="captcha" class="g-recaptcha" data-sitekey="6LdQIMQmAAAAAO7BYxSy8cE-yIzmyaexIv072iTTA"></div>
            </div>
            <div class="form__item">
                <button type="submit" class="btn" v-on:click="sendInfo()">Отправить</button>
            </div>
        </div>
    </div>
</template>

<script>
import action from '../services/main.js'

export default {
    name: 'OfferSocial',
    data() {
        return {
            form: {
                name: '',
                url: '',
                social: [''],
                text: ''
            }
        }
    },
    methods: {
        sendInfo() {
            if(this.form.name && this.form.url && this.form.social[0] && this.form.text) {
                let captcha = grecaptcha.getResponse();

                if (!captcha.length) {
                    this.$notify({
                        title: "Внимание",
                        text: "Введите капчу",
                        duration: 2000,
                        type: 'warn',
                    }); 
                }else {
                    action.addMusic(this.form, captcha).then((resp) => {
                        if(resp.data.code == 200) {
                            this.$notify({
                                title: "Успешно",
                                text: resp.data.message,
                                duration: 2000,
                                type: 'success',
                            }); 
                            this.form.name = '',
                            this.form.url = ''
                            this.form.text = ''
                        } else {
                            this.$notify({
                                title: "Ошибка",
                                text: resp.data.message,
                                duration: 2000,
                                type: 'error',
                            }); 
                        }
                    })
                }
            }else {
                this.$notify({
                    title: "Внимание",
                    text: "Укажите название, ссылку на видео и соц сеть.",
                    duration: 2000,
                    type: 'warn',
                }); 
            }
        },
        onSuccess(token) {
            grecaptcha.reset('cptch');
        }
    },
    mounted() {
        if (typeof grecaptcha !== 'undefined') {
            grecaptcha.render(this.$refs.captcha, {
                sitekey: '6LdQIMQmAAAAAO7BYxSy8cE-yIzmyexIv072iTTA',
                callback: this.onSuccess
            })
        }
    }
}
</script>

<style scoped>
.ar {
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    box-sizing: border-box;
}
.section__content {
    max-width: 500px;
    margin: 0 auto;
}
.content__form {
    width: 100%;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.btn:hover {
    cursor: pointer;
}
.minus__btn {
    background: #F8F8F8;
    border-radius: 50%;
    padding: 5px;
}
.minus__btn:hover {
    cursor: pointer;
}
.d {
    align-items: center;
    display: flex;
    gap: 20px;
}
.plus__btn {
    background: #F8F8F8;
    border-radius: 50%;
    padding: 5px;
}
.plus__btn:hover {
    cursor: pointer;
}
</style>