<template>
    <div class="container">
        <div class="container__wrap">
            <div class="section">
                <div class="section__name">Предложи песню</div>
                <OfferDefault v-if="this.$route.name == 'Offer'" />
                <OfferSocial v-if="this.$route.name == 'OfferAuthor'" />
            </div>  

            <div class="section">
                <div class="section__name">Песни</div>
                <div class="section__content" style="max-width: 100%">
                    <div class="content__form" style="overflow-x:auto;">
                        
                        <div class="loader_wrp" v-bind:style="{ display: loaderDisplay }">
                            <span class="loader"></span>
                        </div>
                        <table class="content__table">
                            <tr class="table__line">
                                <th class="row__name">
                                    Название
                                    <input type="text" v-model="searchQuery" placeholder="Введите название...">
                                </th>
                                <th class="row__name">Ссылка</th>
                                <th class="row__name">
                                    <div class="psort">
                                        <div class="n">Голос</div>
                                        <svg v-show="this.sort" v-on:click="srt()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.33332 13.3333L16 20L22.6667 13.3333" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <svg v-show="!this.sort" v-on:click="srt()" id="" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22.6667 18.6667L16 12L9.33334 18.6667" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <svg v-on:click="reload()" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 26.6666C13.0222 26.6666 10.5 25.6333 8.43331 23.5666C6.36665 21.5 5.33331 18.9778 5.33331 16C5.33331 13.0222 6.36665 10.5 8.43331 8.43331C10.5 6.36665 13.0222 5.33331 16 5.33331C17.5333 5.33331 19 5.64976 20.4 6.28265C21.8 6.91554 23 7.82131 24 8.99998V5.33331H26.6666V14.6666H17.3333V12H22.9333C22.2222 10.7555 21.2498 9.77776 20.016 9.06665C18.7822 8.35554 17.4435 7.99998 16 7.99998C13.7778 7.99998 11.8889 8.77776 10.3333 10.3333C8.77776 11.8889 7.99998 13.7778 7.99998 16C7.99998 18.2222 8.77776 20.1111 10.3333 21.6666C11.8889 23.2222 13.7778 24 16 24C17.7111 24 19.2555 23.5111 20.6333 22.5333C22.0111 21.5555 22.9778 20.2666 23.5333 18.6666H26.3333C25.7111 21.0222 24.4444 22.9444 22.5333 24.4333C20.6222 25.9222 18.4444 26.6666 16 26.6666Z" fill="black"/>
                                        </svg>
                                    </div>
                                </th>
                                <th class="row__name">Статус</th>
                            </tr>

                            <tr v-if="!searchQuery" v-for="(item, index) in paginatedData" :key="index">
                                <td>{{item.name}}</td> 
                                <td style="display: flex; align-items: center; gap: 10px">
                                    <img class="video_img" :src="item.img" alt="">
                                    <a class="link" :href="item.url">{{item.url}}</a>
                                </td> 
                                <td>
                                        <div v-if="item.status != 2" class="golos_group">
                                            <div class="likes">
                                                <div class="count">{{item.stats.likes}}</div>
                                                <svg v-on:click="like(1, index, item.id)" v-if="!this.liked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.16667 7.50004V17.5H0.833336V7.50004H4.16667ZM7.5 17.5C7.05798 17.5 6.63405 17.3244 6.32149 17.0119C6.00893 16.6993 5.83334 16.2754 5.83334 15.8334V7.50004C5.83334 7.04171 6.01667 6.62504 6.325 6.32504L11.8083 0.833374L12.6917 1.71671C12.9167 1.94171 13.0583 2.25004 13.0583 2.59171L13.0333 2.85837L12.2417 6.66671H17.5C17.942 6.66671 18.366 6.8423 18.6785 7.15486C18.9911 7.46742 19.1667 7.89135 19.1667 8.33337V10C19.1667 10.2167 19.125 10.4167 19.05 10.6084L16.5333 16.4834C16.2833 17.0834 15.6917 17.5 15 17.5H7.5ZM7.5 15.8334H15.025L17.5 10V8.33337H10.175L11.1167 3.90004L7.5 7.52504V15.8334Z" fill="black"/>
                                                </svg>
                                                <svg v-on:click="like(1, index, item.id)" v-if="this.liked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.1667 8.33331C19.1667 7.89129 18.9911 7.46736 18.6785 7.1548C18.366 6.84224 17.942 6.66665 17.5 6.66665H12.2333L13.0333 2.85831C13.05 2.77498 13.0583 2.68331 13.0583 2.59165C13.0583 2.24998 12.9167 1.93331 12.6917 1.70831L11.8083 0.833313L6.325 6.31665C6.01667 6.62498 5.83333 7.04165 5.83333 7.49998V15.8333C5.83333 16.2753 6.00893 16.6993 6.32149 17.0118C6.63405 17.3244 7.05797 17.5 7.5 17.5H15C15.6917 17.5 16.2833 17.0833 16.5333 16.4833L19.05 10.6083C19.125 10.4166 19.1667 10.2166 19.1667 9.99998V8.33331ZM0.833334 17.5H4.16667V7.49998H0.833334V17.5Z" fill="black"/>
                                                </svg>
                                            </div>
                                            <div class="likes">
                                                <svg v-on:click="like(2, index, item.id)" v-if="this.disliked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_52_177)">
                                                        <path d="M0.833334 11.6667C0.833334 12.1087 1.00893 12.5326 1.32149 12.8452C1.63405 13.1578 2.05797 13.3334 2.5 13.3334L7.76667 13.3334L6.96667 17.1417C6.95 17.225 6.94167 17.3167 6.94167 17.4084C6.94167 17.75 7.08333 18.0667 7.30833 18.2917L8.19167 19.1667L13.675 13.6834C13.9833 13.375 14.1667 12.9584 14.1667 12.5L14.1667 4.16669C14.1667 3.72466 13.9911 3.30074 13.6785 2.98817C13.366 2.67561 12.942 2.50002 12.5 2.50002L5 2.50002C4.30834 2.50002 3.71667 2.91668 3.46667 3.51668L0.950001 9.39169C0.875001 9.58335 0.833335 9.78335 0.833335 10L0.833334 11.6667ZM19.1667 2.50002L15.8333 2.50002L15.8333 12.5L19.1667 12.5L19.1667 2.50002Z" fill="black"/>
                                                    </g>
                                                <defs>
                                                    <clipPath id="clip0_52_177">
                                                        <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)"/>
                                                    </clipPath>
                                                </defs>
                                                </svg>
                                                <svg v-on:click="like(2, index, item.id)" v-if="!this.disliked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8333 12.5V2.5H19.1667V12.5H15.8333ZM12.5 2.5C12.942 2.5 13.366 2.67559 13.6785 2.98816C13.9911 3.30072 14.1667 3.72464 14.1667 4.16667V12.5C14.1667 12.9583 13.9833 13.375 13.675 13.675L8.19167 19.1667L7.30834 18.2833C7.08334 18.0583 6.94167 17.75 6.94167 17.4L6.96667 17.1417L7.75834 13.3333H2.5C2.05798 13.3333 1.63405 13.1577 1.32149 12.8452C1.00893 12.5326 0.833336 12.1087 0.833336 11.6667V10C0.833336 9.78333 0.875003 9.58333 0.950003 9.39167L3.46667 3.51667C3.71667 2.91667 4.30834 2.5 5 2.5H12.5ZM12.5 4.16667H4.975L2.5 10V11.6667H9.81667L8.875 16.1L12.5 12.475V4.16667Z" fill="black"/>
                                                </svg>
                                                <div class="count">{{item.stats.dislikes}}</div>
                                            </div>
                                        </div>
                                    </td> 
                                <td>
                                    <div v-if="item.status == 2" style="display: flex">
                                        <div class="status">Спето</div>
                                    </div>
                                </td> 
                            </tr>
                            
                            <tr v-if="searchQuery" v-for="(item, index) in filterPaginatedData" :key="index">
                               <td>{{item.name}}</td> 
                               <td style="display: flex; align-items: center; gap: 10px">
                                <img class="video_img" :src="item.img" alt="">
                                <a class="link" :href="item.url">{{item.url}}</a>
                               </td> 
                               <td>
                                        <div v-if="item.status != 2" class="golos_group">
                                            <div class="likes">
                                                <div class="count">{{item.stats.likes}}</div>
                                                <svg v-on:click="like(1, index, item.id)" v-if="!this.liked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.16667 7.50004V17.5H0.833336V7.50004H4.16667ZM7.5 17.5C7.05798 17.5 6.63405 17.3244 6.32149 17.0119C6.00893 16.6993 5.83334 16.2754 5.83334 15.8334V7.50004C5.83334 7.04171 6.01667 6.62504 6.325 6.32504L11.8083 0.833374L12.6917 1.71671C12.9167 1.94171 13.0583 2.25004 13.0583 2.59171L13.0333 2.85837L12.2417 6.66671H17.5C17.942 6.66671 18.366 6.8423 18.6785 7.15486C18.9911 7.46742 19.1667 7.89135 19.1667 8.33337V10C19.1667 10.2167 19.125 10.4167 19.05 10.6084L16.5333 16.4834C16.2833 17.0834 15.6917 17.5 15 17.5H7.5ZM7.5 15.8334H15.025L17.5 10V8.33337H10.175L11.1167 3.90004L7.5 7.52504V15.8334Z" fill="black"/>
                                                </svg>
                                                <svg v-on:click="like(1, index, item.id)" v-if="this.liked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.1667 8.33331C19.1667 7.89129 18.9911 7.46736 18.6785 7.1548C18.366 6.84224 17.942 6.66665 17.5 6.66665H12.2333L13.0333 2.85831C13.05 2.77498 13.0583 2.68331 13.0583 2.59165C13.0583 2.24998 12.9167 1.93331 12.6917 1.70831L11.8083 0.833313L6.325 6.31665C6.01667 6.62498 5.83333 7.04165 5.83333 7.49998V15.8333C5.83333 16.2753 6.00893 16.6993 6.32149 17.0118C6.63405 17.3244 7.05797 17.5 7.5 17.5H15C15.6917 17.5 16.2833 17.0833 16.5333 16.4833L19.05 10.6083C19.125 10.4166 19.1667 10.2166 19.1667 9.99998V8.33331ZM0.833334 17.5H4.16667V7.49998H0.833334V17.5Z" fill="black"/>
                                                </svg>
                                            </div>
                                            <div class="likes">
                                                <svg v-on:click="like(2, index, item.id)" v-if="this.disliked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_52_177)">
                                                        <path d="M0.833334 11.6667C0.833334 12.1087 1.00893 12.5326 1.32149 12.8452C1.63405 13.1578 2.05797 13.3334 2.5 13.3334L7.76667 13.3334L6.96667 17.1417C6.95 17.225 6.94167 17.3167 6.94167 17.4084C6.94167 17.75 7.08333 18.0667 7.30833 18.2917L8.19167 19.1667L13.675 13.6834C13.9833 13.375 14.1667 12.9584 14.1667 12.5L14.1667 4.16669C14.1667 3.72466 13.9911 3.30074 13.6785 2.98817C13.366 2.67561 12.942 2.50002 12.5 2.50002L5 2.50002C4.30834 2.50002 3.71667 2.91668 3.46667 3.51668L0.950001 9.39169C0.875001 9.58335 0.833335 9.78335 0.833335 10L0.833334 11.6667ZM19.1667 2.50002L15.8333 2.50002L15.8333 12.5L19.1667 12.5L19.1667 2.50002Z" fill="black"/>
                                                    </g>
                                                <defs>
                                                    <clipPath id="clip0_52_177">
                                                        <rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)"/>
                                                    </clipPath>
                                                </defs>
                                                </svg>
                                                <svg v-on:click="like(2, index, item.id)" v-if="!this.disliked.includes(item.id)" class="icn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8333 12.5V2.5H19.1667V12.5H15.8333ZM12.5 2.5C12.942 2.5 13.366 2.67559 13.6785 2.98816C13.9911 3.30072 14.1667 3.72464 14.1667 4.16667V12.5C14.1667 12.9583 13.9833 13.375 13.675 13.675L8.19167 19.1667L7.30834 18.2833C7.08334 18.0583 6.94167 17.75 6.94167 17.4L6.96667 17.1417L7.75834 13.3333H2.5C2.05798 13.3333 1.63405 13.1577 1.32149 12.8452C1.00893 12.5326 0.833336 12.1087 0.833336 11.6667V10C0.833336 9.78333 0.875003 9.58333 0.950003 9.39167L3.46667 3.51667C3.71667 2.91667 4.30834 2.5 5 2.5H12.5ZM12.5 4.16667H4.975L2.5 10V11.6667H9.81667L8.875 16.1L12.5 12.475V4.16667Z" fill="black"/>
                                                </svg>
                                                <div class="count">{{item.stats.dislikes}}</div>
                                            </div>
                                        </div>
                                    </td> 
                                <td>
                                    <div v-if="item.status == 2" style="display: flex">
                                        <div class="status">Спето</div>
                                    </div>
                                </td> 
                            </tr>


                        </table>
                        <div class="pagination">
                            <div v-on:click="pag(1)" class="pagination__btn">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.6667 9.33335L12 16L18.6667 22.6667" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="pagination__num">
                                {{this.paginationList}}
                            </div>
                            ...
                            <div class="pagination__num">
                                {{this.paginationMax}}
                            </div>
                            <div v-on:click="pag(2)" class="pagination__btn">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3333 22.6666L20 16L13.3333 9.33331" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OfferDefault from '../components/OfferDefault.vue'
import OfferSocial from '../components/OfferSocial.vue'
import {useLikesStore} from '@/store/likesStore'
import action from '../services/main.js'

const store = useLikesStore();

export default {
    name: "Offer",
    components: {
        OfferDefault, OfferSocial
    },
    data() {
        return {
            datas: [],
            paginationList: 1,
            paginationMax: 0,
            paginatedData: [],
            paginationCount: 5,
            loaderDisplay: 'none',
            sort: 0,
            liked: [],
            disliked: [],
            searchQuery: '',
            form: {
                name: '',
                url: ''
            }
        }
    },
    methods: {
        pag(act) {
            switch (act) {
                case 1:
                    if(this.paginationList == 1) return;
                    this.paginationList--;
                    this.sortActive = true;
                    break;
                case 2:
                    if((this.datas.length / this.paginationCount) <= this.paginationList) return;
                    this.paginationList++;
                    this.sortActive = true;
                    break;
                default:
                    break;
            }

            let a = (this.paginationList - 1) * this.paginationCount;
            let b = this.paginationCount + a;
            this.paginatedData = [];

            this.loaderDisplay = 'flex'

            setTimeout(()=>{
                for (let i = a; i < b; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i]);
                    }
                }
                this.loaderDisplay = 'none'
                this.sortActive = false;
            }, 200)
        },
        srt() {
            if(this.sort) {
                this.datas = this.datas.sort((a, b) => a.stats.likes - b.stats.likes);
                this.sort = !this.sort;
            } else {
                this.datas = this.datas.sort((a, b) => b.stats.likes - a.stats.likes);
                this.sort = !this.sort; 
            }

            const filteredArr = this.datas.filter(item => item.status !== 2);
            const itemsWithKey = this.datas.filter(item => item.status === 2);
            this.datas = filteredArr.concat(itemsWithKey);
            
            let a = (this.paginationList - 1) * this.paginationCount;
            let b = this.paginationCount + a;
            this.paginatedData = [];

            this.loaderDisplay = 'flex'

            setTimeout(()=>{
                for (let i = a; i < b; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i]);
                    }
                }
                this.loaderDisplay = 'none'
            }, 200)
        },
        like(act, index, iid) {
            switch (act) {
                case 1:
                    if(!this.liked.includes(iid)) {

                        if(this.disliked.includes(iid)) {
                            store.like(4, index, iid).then(res => {
                                this.paginatedData[index].stats.dislikes--;
                                this.disliked = res[1];
                            }).then(res => {
                                store.like(1, index, iid).then(res => {
                                    this.paginatedData[index].stats.likes++;
                                    this.liked = res[0];
                                }).catch(err => {
                                    this.$notify({
                                        title: "Ошибка",
                                        text: err,
                                        duration: 2000,
                                        type: 'error',
                                    });
                                })
                            })
                        }else {
                            store.like(1, index, iid).then(res => {
                                this.paginatedData[index].stats.likes++;
                                this.liked = res[0];
                            }).catch(err => {
                                this.$notify({
                                    title: "Ошибка",
                                    text: err,
                                    duration: 2000,
                                    type: 'error',
                                });
                            })
                        }

                    }else {
                        store.like(2, index, iid).then(res => {
                            this.paginatedData[index].stats.likes--;
                            this.liked = res[0];
                        })
                    }
                    break;
                case 2:
                    if(!this.disliked.includes(iid)) {

                        if(this.liked.includes(iid)) {
                            store.like(2, index, iid).then(res => {
                                this.paginatedData[index].stats.likes--;
                                this.liked = res[0];
                            }).then(res => {
                                store.like(3, index, iid).then(res => {
                                    this.paginatedData[index].stats.dislikes++;
                                    this.disliked = res[1];
                                }).catch(err => {
                                    this.$notify({
                                        title: "Ошибка",
                                        text: err,
                                        duration: 2000,
                                        type: 'error',
                                    });
                                })
                            })
                        }else {
                            store.like(3, index, iid).then(res => {
                                this.paginatedData[index].stats.dislikes++;
                                this.disliked = res[1];
                            }).catch(err => {
                                this.$notify({
                                    title: "Ошибка",
                                    text: err,
                                    duration: 2000,
                                    type: 'error',
                                });
                            })
                        }

                    }else {
                        store.like(4, index, iid).then(res => {
                            this.paginatedData[index].stats.dislikes--;
                            this.disliked = res[1];
                        })
                    }
                    break;
            
                default:
                    break;
            }
        },
        reload() {
            this.paginatedData = [];

            this.datas = this.datas.sort((a, b) => b.id - a.id);
            const filteredArr = this.datas.filter(item => item.status != 2);
            const itemsWithKey = this.datas.filter(item => item.status == 2);
            this.datas = filteredArr.concat(itemsWithKey);
                
            let a = this.datas.length / this.paginationCount;
            for (let i = 0; i < this.paginationCount; i++) {
                if(this.datas[i]) {
                    this.paginatedData.push(this.datas[i * this.paginationList]);
                }
            }
        }
    },
    mounted() {
        let a = store.getStatus;
        this.liked = a[0];
        this.disliked = a[1];

        if(this.$route.name == 'Offer') {
            action.getMusic(false, 0).then(res => {
                this.datas = res.data;

                const filteredArr = this.datas.filter(item => item.status != 2);
                const itemsWithKey = this.datas.filter(item => item.status == 2);
                this.datas = filteredArr.concat(itemsWithKey);
                    
                let a = this.datas.length / this.paginationCount;
                this.paginationMax = Math.ceil(a);
                for (let i = 0; i < this.paginationCount; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i * this.paginationList]);
                    }
                }
            })
        }else {
            action.getMusic(false, 1).then(res => {
                this.datas = res.data;

                const filteredArr = this.datas.filter(item => item.status != 2);
                const itemsWithKey = this.datas.filter(item => item.status == 2);
                this.datas = filteredArr.concat(itemsWithKey);
                    
                let a = this.datas.length / this.paginationCount;
                this.paginationMax = Math.ceil(a);
                for (let i = 0; i < this.paginationCount; i++) {
                    if(this.datas[i]) {
                        this.paginatedData.push(this.datas[i * this.paginationList]);
                    }
                }
            })
        }
    },
    computed: {
        filterPaginatedData() {
            return this.datas.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        }
    },
    watch: {
        '$route.name': function (params) {
            this.datas = [];
            this.paginatedData = [];
            if(this.$route.name == 'Offer') {
                action.getMusic(false, 0).then(res => {
                    this.datas = res.data;

                    const filteredArr = this.datas.filter(item => item.status != 2);
                    const itemsWithKey = this.datas.filter(item => item.status == 2);
                    this.datas = filteredArr.concat(itemsWithKey);
                        
                    let a = this.datas.length / this.paginationCount;
                    this.paginationMax = Math.ceil(a);
                    this.paginationList = 1;
                    for (let i = 0; i < this.paginationCount; i++) {
                        if(this.datas[i]) {
                            this.paginatedData.push(this.datas[i * this.paginationList]);
                        }
                    }
                })
            }else {
                action.getMusic(false, 1).then(res => {
                    this.datas = res.data;

                    const filteredArr = this.datas.filter(item => item.status != 2);
                    const itemsWithKey = this.datas.filter(item => item.status == 2);
                    this.datas = filteredArr.concat(itemsWithKey);
                        
                    let a = this.datas.length / this.paginationCount;
                    this.paginationMax = Math.ceil(a);
                    this.paginationList = 1;
                    for (let i = 0; i < this.paginationCount; i++) {
                        if(this.datas[i]) {
                            this.paginatedData.push(this.datas[i * this.paginationList]);
                        }
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.psort {
    display: flex; 
    align-items: center;
    gap: 10px;
}
.psort svg {
    background-color:#F8F8F8;
    border-radius: 50%;
    display: flex;
    padding: 5px;
}
.psort svg:hover {
    cursor: pointer;
}
.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.pagination__btn {
    background-color:#F8F8F8;
    border-radius: 50%;
    display: flex;
    padding: 5px;
}
.pagination__btn:hover {
    cursor: pointer;
}
.pagination__num {
    font-size: 16px;
    font-weight: 500;
}
.video_img {
    object-fit:cover;
    min-width:200px;
    height:90px;
    border-radius: 15px;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.btn:hover {
    cursor: pointer;
}
.content__table {
    width: 100%;
}
th, td {
    padding: 15px 20px;
    border-bottom: 1px solid #DDDDDD;
}
.row__name {
    text-align: left;
    font-weight: 700;
    font-size: 16px;
}
.link {
    color: #00B3FF;
    text-decoration: underline;
}
.icn:hover {
    cursor: pointer;
}

.golos_group {
    display: flex;
    align-items: center;
    gap: 10px;
}
.likes {
    display: flex;
    align-items: center;
    gap: 5px;
}
.status {
    background-color: #D1E4FF;
    padding: 5px 10px;
    border-radius: 15px;
}

.section {
    margin: 50px 0;
}
.section__name {
    font-size: 32px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}
.section__content {
    max-width: 500px;
    margin: 0 auto;
}
.content__form {
    width: 100%;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
}
</style>