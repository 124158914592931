<template>
    <div class="section__content">
        <div class="content__form">
            <div class="form__item">
                <label class="item__name" for="inptName">Название:</label>
                <input required class="item__input" v-model="this.form.name" type="text" placeholder="Введите название" id="inptName">
            </div>
            <div class="form__item">
                <label class="item__name" for="inptName">Ссылка:</label>
                <input required class="item__input" v-model="this.form.url" type="text" placeholder="Введите ссылку" id="inptName">
            </div>
            <div class="form__item"  style="align-items: center;" >
                <div id="cptch" ref="captcha" class="g-recaptcha" data-sitekey="6LdQIMQmAAAAAO7BYxSy8cE-yIzmyaexIv072iTTA"></div>
            </div>
            <div class="form__item">
                <button type="submit" class="btn" v-on:click="sendInfo()">Отправить</button>
            </div>
        </div>
    </div>
</template>

<script>
import action from '../services/main.js'

export default {
    name: 'OfferDefault',
    data() {
        return {
            form: {
                name: '',
                url: ''
            }
        }
    },
    methods: {
        sendInfo() {
            if(this.form.name && this.form.url) {
                let captcha = grecaptcha.getResponse();

                if (!captcha.length) {
                    this.$notify({
                        title: "Внимание",
                        text: "Введите капчу",
                        duration: 2000,
                        type: 'warn',
                    }); 
                } else {
                    action.addMusic(this.form, captcha).then((resp) => {
                        if(resp.data.code == 200) {
                            this.$notify({
                                title: "Успешно",
                                text: resp.data.message,
                                duration: 2000,
                                type: 'success',
                            }); 
                            this.form.name = '',
                            this.form.url = ''
                        } else {
                            this.$notify({
                                title: "Ошибка",
                                text: resp.data.message,
                                duration: 2000,
                                type: 'error',
                            }); 
                        }
                    })
                }

            }else {
                this.$notify({
                    title: "Внимание",
                    text: "Укажите название и ссылку",
                    duration: 2000,
                    type: 'warn',
                }); 
            }
        },
        onSuccess(token) {
            grecaptcha.reset('cptch');
        }
    },
    mounted() {
        if (typeof grecaptcha !== 'undefined' || typeof grecaptcha.render !=='undefined') {
            try {
                grecaptcha.render('cptch', {
                    sitekey: '6LdQIMQmAAAAAO7BYxSy8cE-yIzmyexIv072iTTA',
                    callback: this.onSuccess,
                    'expired-callback': this.expired
                });
            } catch (error) {
                this.$router.go(this.$router.currentRoute)
            }
        }
    }
}
</script>

<style scoped>
.section__content {
    max-width: 500px;
    margin: 0 auto;
}
.content__form {
    width: 100%;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.btn:hover {
    cursor: pointer;
}
</style>