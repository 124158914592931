import { defineStore } from 'pinia'
import action from '../services/main.js'

export const useLikesStore = defineStore('likes', {
    state: () => ({ 
        isLiked: localStorage.getItem('llist') ? true : false
    }),
    getters: {
        getStatus (state) {
            if(state.isLiked == false) {
                let a = JSON.stringify([[], []]);
                localStorage.setItem('llist', a);
            }
            return JSON.parse(localStorage.getItem('llist'));
        }
    },
    actions: {
        like(act, id, iid) {
            if(act == 1) {
                let a = JSON.parse(localStorage.getItem('llist'));
                if(!a[0].includes(iid)) {

                    return new Promise((resolve, reject) => {
                        action.golike(iid, 1).then(res => {
                            if(res.data.code === 200) {
                                a[0].push(iid);
                                localStorage.setItem('llist', JSON.stringify(a));
    
                                resolve(JSON.parse(localStorage.getItem('llist')));
                            }else {
                                reject("Вы уже оценивали данную запись.");
                            }
                        })
                    })

                }
            }else if(act == 2) {
                let a = JSON.parse(localStorage.getItem('llist'));
                if(a[0].includes(iid)) {
                    
                    let index = a[0].indexOf(iid);

                    if (index > -1) {
                        a[0].splice(index, 1);
                    }

                    return new Promise((resolve, reject) => {
                        action.golike(iid, 2).then(res => {
                            if(res.data.code === 200) {
                                localStorage.setItem('llist', JSON.stringify(a));
    
                                resolve(JSON.parse(localStorage.getItem('llist')));
                            }else {
                                reject("Вы уже оценивали данную запись.");
                            }
                        })
                    })

                }
            }else if(act == 3) {
                let a = JSON.parse(localStorage.getItem('llist'));
                if(!a[1].includes(iid)) {
                    return new Promise((resolve, reject) => {
                        action.golike(iid, 3).then(res => {
                            if(res.data.code === 200) {
                                a[1].push(iid);
                                localStorage.setItem('llist', JSON.stringify(a));
    
                                resolve(JSON.parse(localStorage.getItem('llist')));
                            }else {
                                reject("Вы уже оценивали данную запись.");
                            }
                        })
                    })
                }
            }else if(act == 4) {
                let a = JSON.parse(localStorage.getItem('llist'));
                if(a[1].includes(iid)) {
                    let index = a[1].indexOf(iid);

                    if (index > -1) {
                        a[1].splice(index, 1);
                    }

                    return new Promise((resolve, reject) => {
                        action.golike(iid, 4).then(res => {
                            if(res.data.code === 200) {
                                localStorage.setItem('llist', JSON.stringify(a));
    
                                resolve(JSON.parse(localStorage.getItem('llist')));
                            }else {
                                reject("Вы уже оценивали данную запись.");
                            }
                        })
                    })
                }
            }

            console.log(JSON.parse(localStorage.getItem('llist')));
            
        }
    }
})